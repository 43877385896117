import { AdyenCheckoutData, CheckoutState } from '../checkoutSlice';
import useLocalStorageState from 'use-local-storage-state';
import { AdyenCheckout, CoreConfiguration, Dropin, DropinConfiguration } from '@adyen/adyen-web/auto';
import '@adyen/adyen-web/dist//es/adyen.css';
import { useEffect, useMemo, useRef } from 'react';
import { getRedirectUrl } from '../CheckoutResult/getRedirectUrl';
import { useHistory } from 'react-router-dom';

export default function AdyenHandler({
  checkoutData,
  paymentData
}: {
  checkoutData: CheckoutState;
  paymentData: AdyenCheckoutData;
}) {
  const paymentContainer = useRef(null);
  const history = useHistory();

  const configuration = useMemo<CoreConfiguration>(() => {
    return {
      environment: paymentData.environment,
      session: paymentData.session,
      clientKey: paymentData.clientKey,
      analytics: {
        enabled: false
      },
      beforeSubmit: async (data, components, actions) => {
        localStorage.setItem(`order-${paymentData.orderId}`, JSON.stringify(checkoutData));
        localStorage.setItem(
          `order-${paymentData.orderId}-paymentSession`,
          JSON.stringify(paymentData)
        );
        actions.resolve(data);
      },
      onPaymentCompleted: (response, component) => {
        const resultCode = response.resultCode;
        const redirecturl = getRedirectUrl(resultCode, paymentData.orderId);
        history.replace(redirecturl);
      },
      onPaymentFailed: (response, component) => {
        if (response) {
          const resultCode = response.resultCode;
          const redirecturl = getRedirectUrl(resultCode, paymentData.orderId);
          history.replace(redirecturl);
        }
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      }
    };
  }, [paymentData]);

  const dropinConfiguration = useMemo(() => {
    const config: DropinConfiguration = {
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true
        }
      }
    };
    return config;
  }, []);

  useEffect(() => {
    let ignore = false;
    const createCheckout = async () => {
      const checkout = await AdyenCheckout(configuration);
      //if(isMounted.current === true) return;
      if (ignore === true) return;

      if (paymentContainer.current) {
        new Dropin(checkout, dropinConfiguration).mount(paymentContainer.current);
      }
    };

    createCheckout();

    return () => {
      ignore = true;
    };
  }, [configuration, dropinConfiguration]);

  return (
    <div className="payment-container">
      <div ref={paymentContainer} className="payment"></div>
    </div>
  );
}
