import { useHistory, useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { AdyenCheckoutData, CheckoutState } from '../checkoutSlice';
import { useEffect, useMemo } from 'react';
import { AdyenCheckout, CoreConfiguration } from '@adyen/adyen-web/auto';
import { getRedirectUrl } from './getRedirectUrl';

export type CheckoutResultRouteParams = {
  id: string;
}

export default function CheckoutResult() {
  let { id: orderId } = useParams<CheckoutResultRouteParams>();
  const history = useHistory();
  const [paymentSession, setpaymentSession] = useLocalStorageState<AdyenCheckoutData>(
    `order-${orderId}-paymentSession`
  );

  const configuration = useMemo<CoreConfiguration | undefined>(() => {
    if (paymentSession) {
      return {
        environment: paymentSession.environment,
        session: { id: paymentSession.session.id },
        clientKey: paymentSession.clientKey,
        analytics: {
          enabled: false
        },
        onPaymentCompleted: (response, component) => {
          const resultCode = response.resultCode;
          const redirecturl = getRedirectUrl(resultCode, orderId);
          history.push(redirecturl);
        },
        onError: (error, component) => {
          console.error('adyen onError', error.name, error.message, error.stack, component);
        }
      };
    }
    return undefined;
  }, [history, orderId, paymentSession]);

  useEffect(() => {
    let ignore = false;
    const createCheckout = async () => {
      if (ignore === true) return;

      if (configuration) {
        const redirectResult = new URLSearchParams(window.location.search).get('redirectResult') ?? undefined;
        const checkout = await AdyenCheckout(configuration);
        checkout.submitDetails({ details: { redirectResult } });
      }
    };

    createCheckout();

    return () => {
      ignore = true;
    };
  }, [configuration]);

  return <>Redirect page</>;
}

