import { ResultCode } from '@adyen/adyen-web/auto';

export function getRedirectUrl(resultCode: ResultCode, orderId: string) {
  switch (resultCode) {
    case 'Authorised':
      return `/checkout/success/orderId/${orderId}`;
    case 'Refused':
      return `/checkout/failed/orderId/${orderId}`;
    case 'Pending':
    case 'Received':
      return `/checkout/pending/orderId/${orderId}`;
    case 'Cancelled':
      return `/checkout/cancelled/orderId/${orderId}`;
    default:
      return `/checkout/error/orderId/${orderId}`;
  }
}
